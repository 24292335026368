import {
  BrowserRouter,
  Routes,
  Route,
} from "react-router-dom";
import Authentication from "./Authentication";
import AddSeller from './components/AddSeller';
import Adduser from "./components/Adduser";
import Template from './components/Template';
import Dashboard from './pages/Dashboard';
import MultiProducts from './pages/MultiProducts';

function App() {
  return (
  <Authentication>
    <BrowserRouter>
      <Routes>
        <Route path="/" element={<Template> <Dashboard /> </Template>} />
        <Route path="/addSingleProduct" element={<Template> <AddSeller /> </Template>} />
        <Route path="/addMultipleProducts" element={<Template><MultiProducts /></Template>} />
        <Route path="/adduser" element={<Template>  <Adduser/></Template>}  />
      </Routes>
    </BrowserRouter>
  </Authentication>
  );
}

export default App;
