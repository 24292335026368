import React, { useEffect, useState } from "react";
import styled from "styled-components";
import Footer from "./Footer";
// import Navbar from "./Navbar";
import { toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css'
import Imageuploader from "./Imageuploader";
import axios from "axios";
import { PREPROCESS_URL, MASK_GENERATION_URL } from '../consts'
import { storeImgInStorage, b64_jpg } from '../utils'
import { collection, addDoc, updateDoc, getDocs } from 'firebase/firestore'
import { db } from '../firebase-config'

toast.configure()

function AddSeller() {
  const [data, setData] = useState({
    name: "",
    brand: "",
    category: "",
    subCategory: "",
    price: "",
    productUrl: "",
    images: "",
    description: "",
    userId: "",
  });
  const { 
    name, 
    brand, 
    category, 
    subCategory, 
    price, 
    productUrl, 
    images,
    description,
    userId
  } = data
  const [image, setImage] = useState("")
  const [users, setUsers] = useState([])

  useEffect(()=>{
    getDocs(collection(db, 'Users'))
    .then(snapshot => {
      const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
      setUsers(data)
    })
  }, [])

  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }
  const notify = () => {
    toast.success('Our team will contact you soon!', {position:toast.POSITION.TOP_CENTER});
  }
  const warnify = () => {
    toast.warn('Every field is compulsory!', {position:toast.POSITION.TOP_CENTER})
  }

  const handleSubmit = async e => {
    e.preventDefault();
    if(name&& brand&& category&& subCategory&& price&& productUrl&& images&& description&& image && userId){
      // Call to preprocess image
      if(image.length) {
        const imgData = JSON.stringify({"image": image})
        try {
          const response = await axios.post(PREPROCESS_URL, imgData)
          const b64_bgremoved = response.data
          // Call mask generation API here
          axios.post(MASK_GENERATION_URL, {real_image: b64_bgremoved})
          .then(async res => {
            const docRef = await addDoc(collection(db, "PendingApproval/Products/All"), {});
            const cloth_file = b64_jpg(b64_bgremoved)
            const mask_file  = b64_jpg(res.data.image)

            const cloth_location = `new_product_images/clothes/${docRef.id}.jpg`
            const mask_location  = `new_product_images/edges/${docRef.id}.jpg`
            storeImgInStorage(cloth_file, cloth_location)
            storeImgInStorage(mask_file, mask_location)

            const newData = {...data,
              tryonData: [{
                cloth: cloth_location,
                edge: mask_location
              }],
              tryonCount: 0, isTryon: true,
              likesCount: 0, likesList: [],
              commentsCount: 0,
              currency: "",
              dateAdded: "",
            }
            updateDoc(docRef, newData)
            notify()
          })
          .catch(err => console.log(err))
        } catch(err) {
          console.log(err)
        }
      }
    } else {
      warnify()
    }
  };

  return (
    <Addmerchant1>
      {/* <Navbar id="1" /> */}
      <div className="container1" style={{ margin: "65px" }} >
        <div className="box">
          <div className="join">
            <h2 className="join">Product Detail</h2>
          </div>
          <div className="form">
            <form onSubmit={handleSubmit}>
              <label htmlFor="name">Name</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px",marginTop:"10px"}}
                id="name"
                autoComplete="off"
                name="name"
                value={name}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="name">Brand Name</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px",marginTop:"10px"}}
                id="name"
                autoComplete="off"
                name="brand"
                value={brand}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="category">Category</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="category"
                autoComplete="off"
                name="category"
                value={category}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="frontImage">Front Image</label><br />
              <Imageuploader id="frontImage" image={image} setImage={setImage} />
              <br /> 

              <label htmlFor="subCategory">Sub-Category</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px",marginTop:"10px"}}
                id="subCategory"
                autoComplete="off"
                name="subCategory"
                value={subCategory}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="price">Price</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="price"
                autoComplete="off"
                name="price"
                value={price}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="productUrl">Product URL</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="productUrl"
                autoComplete="off"
                name="productUrl"
                value={productUrl}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="images">Image Links</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="images"
                autoComplete="off"
                name="images"
                value={images}
                onChange={handleChange}
                required={false}
              /><br />

              <label htmlFor="description">Description</label><br />
              <textarea  rows="4" cols="50"
                type="text"
                style={{ width: "300px", marginBottom: "20px" ,height:"100px"}}
                id="description"
                name="description"
                value={description}
                onChange={handleChange}
                required={false}
              >
              </textarea><br />
              <select name="userId" style={{padding:0}} onChange={handleChange} value={userId}>
                <option value="">Null</option>
                {
                  users.map(opt => <option value={opt.id} key={opt.id}>{opt.name}</option>)
                }
              </select>
              <input
                type="submit"  
                style={{ fontsize: "20px", color: "white", height: "40px", width: "100%", marginBottom: "20px", backgroundColor: "#3498DB", border: "none" }} 
                value="Submit" 
              />
            </form>
          </div>
        </div>
      </div>
      <div style={{ width: "100%" }}>
        <Footer />
      </div>
    </Addmerchant1>
  )
}

export default AddSeller;

const Addmerchant1 = styled.div`
  width: 100%;
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
 
 

  .container1{
    ${'' /* marginTop: 700px; */}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
     ${'' /* border: 2px solid red;  */}
    padding: 20px;
}


.join{
    /* border-bottom: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3498DB;
    height: 60px;
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
    color: white;
    width: 100%;
     ${'' /* border: 2px solid orange;  */}
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 20px;
    color: #4A4A4A;
    font-weight: bold;
    /* border: 2px solid blue; */
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
     ${'' /* border: 2px solid red;  */}
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* padding: 10px; */
}
${'' /*   
  @media screen and (max-width: 360px) {
    .box {
      border: 2px solid black;
      margin-right:190px;
      width:30vw;
    }
   
  } */}

 @media screen and (min-width:360px) {
    .box{
        width:95%;
        backgroundColor:blue;
    }
    input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid grey;
  border-radius: 4px;
  box-sizing: border-box;
  height:40px;
}
textarea[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid grey;
  border-radius: 4px;
  box-sizing: border-box;
  height:40px;
}

.body{
  backgroundColor:blue;
}
}
@media screen and (min-width:768px) {
    .box{
        width:50%;
        backgroundColor:blue;
    }
    .container1{
      marginLeft:"50px";
    }
}
@media screen and (min-width:900px) {
    .box{
        width:50%;
        backgroundColor:blue;
    }
    .container1{
      marginLeft:"50px";
    }
}
@media screen and (min-width:1050px) {
    .box{
        width:35%;
        backgroundColor:blue;
    }
    .container1{
      marginLeft:"50px";
    }
}

  @media screen and (max-width: 1010px) {
      height:750px;
      .getheaddiv{
          display:block;
          margin-top:0;
          height:5px;
          z-index:100;
      }
      .gethead{
          margin-top:0;
          font-size:45px;
          padding-top:15px;
          padding-bottom:15px;
          z-index:100;
      }
      .contactform{
          width:95%;
          margin-right:0px;
          // height:640px;
          // margin-top:0;
          margin-top:150px;
      }
      .container1{
      marginLeft:"50px";
    }
  }
`;
