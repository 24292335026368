import { Button, Grid, Input, TextField } from '@mui/material';
import React, { useState } from 'react'

function MultiProducts() {
  const [data, setData] = useState({
    name: "",
    csvFile: null
  })
  const handleChange = (e) => {
    setData({...data, [e.target.name]: e.target.value})
  }
  const handleFileUpload = (e) => {
    setData({...data, csvFile: e.target.files[0]})
  }
  return (
    <Grid container justifyContent="center" alignItems="center">
      <Grid item xs={12} md={5}>
        <form>
          <TextField 
            variant="outlined" 
            name="user"
            value={data.user}
            onChange={handleChange}
            label="User"
            fullWidth
            sx={{
              marginBottom: 2,
            }}
          />
          <Input 
            type="file"
            name="csvFile"
            variant="outlined"
            onChange={handleFileUpload}
            fullWidth
            sx={{
              marginBottom: 2,
            }}
          />
          <Button type="submit" variant="contained" fullWidth> 
            Submit 
          </Button>
        </form>
      </Grid>
    </Grid>
  )
}

export default MultiProducts