import { storage } from "./firebase-config"
import {
    ref, uploadBytes 
} from "firebase/storage"

export const b64_jpg = (b64) => {
    const url = `data:image/jpeg;base64,${b64}`
    const arr = url.split(',')
    const bstr = atob(arr[1])
    let n = bstr.length
    const u8arr = new Uint8Array(n)
    while(n--){
        u8arr[n] = bstr.charCodeAt(n);
    }
    return u8arr
}

export const storeImgInStorage = (u8arr, path) => {
    const storageRef = ref(storage, path);
    const metadata = {
        contentType: 'image/jpeg',
    };
    uploadBytes(storageRef, u8arr, metadata)
    .then(snapshot => console.log("Uploaded file!"))
}