import React, { useCallback } from 'react'
import {useDropzone} from 'react-dropzone'

function Imageuploader({ image, setImage }) {
  const onDrop = useCallback((acceptedFiles) => {
    acceptedFiles.forEach(file => {
      const reader = new FileReader()
      reader.onload = () => {
        const binaryStr = reader.result;
        // console.log(btoa(binaryStr))
        setImage(btoa(binaryStr))
      }
      reader.readAsBinaryString(file)
    });
  }, [])
  const {getRootProps, getInputProps} = useDropzone({onDrop})
  return (
    <div {...getRootProps()}>
      <input {...getInputProps()} />
      {image.length?
      <p>Image uploaded</p>:  // Change according to design requirements
      <p>Click here to Drag and Drop image</p>
      }
    </div>
  )
}




export default Imageuploader