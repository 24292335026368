import React from 'react'
import AppBar  from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import CustomDrawer, { drawerWidth } from './CustomDrawer';
import { handleLogout } from '../Authentication';
import { Button } from '@mui/material';

function Template({children}) {

  return (
    <Box sx={{ display: 'flex' }}>
      <CustomDrawer />
      <AppBar
        position="fixed"
        sx={{ width: `calc(100% - ${drawerWidth}px)`, ml: `${drawerWidth}px` }}
      >
        <Toolbar sx={{justifyContent:"space-between"}}>
          <Typography variant="h6" noWrap component="div">
            Tvish - Admin Panel
          </Typography>
          <Typography variant="h6" noWrap component="div">
            <Button onClick={handleLogout} variant="contained">Logout</Button>
          </Typography>
        </Toolbar>
      </AppBar>
      <Box
        component="main"
        sx={{ flexGrow: 1, bgcolor: 'background.default', p: 3 }}
      >
        <Toolbar />
        {children}
      </Box>
    </Box>
  )
}

export default Template