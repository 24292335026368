import React, { useState,useEffect } from "react";
import styled from "styled-components";
import Footer from "./Footer";
// import { toast } from 'react-toastify';
// import 'react-toastify/dist/ReactToastify.css'
// import { useHistory } from "react-router-dom";
import { collection, addDoc ,updateDoc,getDocs} from "firebase/firestore";
import DatePicker from 'react-datepicker';
import 'react-datepicker/dist/react-datepicker.css'

// toast.configure()
import {db} from '../firebase-config'

function Adduser() {
    const [selectedDate,setSelectedDate] = useState(null)
    const [users, setUsersname] = useState([])
    useEffect(()=>{
        getDocs(collection(db, 'Users'))
        .then(snapshot => {
          const curdata = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
        //   console.log(curdata);
          setUsersname(curdata)
        })
      }, [])
 
  const [data, setData] = useState({
    _uid:"",
    username:"",
    name: "",
    email: "",
    isEmailVerified:false,
    number:"",
    displayUrl:"",
    registrationDatetime:"",
    lastLoggedIn:"",
    savedTryons:{},
    wishlist:{},
    sizeChart:{},
    shareImageModels:{},
    followerList:0,
    followingList:0,
    bio:"",
    blockedList:{},
    savedList:{},
    

    
  });
  const {_uid,username,name,email,isEmailVerified,number,displayUrl,registrationDatetime,lastLoggedin,savedTryons,wishlist, sizeChart,shareImageModels,followerList,followingList,bio,blockedList,savedList} = data
  const handleChange = (e) => {
    setData({ ...data, [e.target.name]: e.target.value });
  }

  

  const handleSubmit = async e => {
    //   console.log(name)
    //   console.log(data.name)
    e.preventDefault();

    
    
var flag=1;
// console.log(username);
// console.log("username above");
users.map(opt => {
    console.log(opt.username)
    //if email should not be duplicate
    // if(opt.username==username||opt.email==email)
    // flag=0;

    if(opt.username==username)
    flag=0;
})

   if(flag==0)
   alert("username Already exist!")
   else
  {
   
  await    addDoc(collection(db,"Users"),{
    _uid:data._uid,
    username:data.username,
    name:data.name,
    email:data.email,
    isEmailVerified:data.isEmailVerified,
    number:data.number,
    displayUrl:data.displayUrl,
    registrationDatetime:selectedDate,
    lastLoggedin:data.lastLoggedIn,
    savedTryons:data.savedTryons,
    wishlist:data.wishlist,
    sizeChart:data.sizeChart,
    shareImageModels:data.shareImageModels,
    followerList:data.followerList,
    followingList:data.followingList,
    bio:data.bio,
    blockedList:data.blockedList,
    savedList:data.savedList,

  }).then(function(res){
  alert("Data is successfulyy added")
  }).catch(function(err){
      alert("Data cant be added")
  })
  setData({ ...data,username:'', name: '', email: ' ', number: '',registrationDatetime:'',bio:'' });

  
  };
}


  return (


    <Adduser1>
     
      <div className="container1" style={{ margin: "65px",marginTop:"120px"}} >

        <div className="box">

          <div className="join">
            <h2 className="join">Add User</h2>
          </div>

          <div className="form">
            <form onSubmit={handleSubmit}>
            <label htmlFor="name">User Name</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px",marginTop:"10px"}}
                id="username"
                autoComplete="off"
                name="username"
                value={username}
                onChange={handleChange}
                required
              /><br />


              <label htmlFor="name">Name</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px",marginTop:"10px"}}
                id="name"
                autoComplete="off"
                name="name"
                value={name}
                onChange={handleChange}
                required
              /><br />

              <label htmlFor="email">Email</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" }}
                id="email"
                autoComplete="off"
                name="email"
                value={email}
                onChange={handleChange}
                required
              /><br />

            <label htmlFor="name">Number</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px",marginTop:"10px"}}
                id="number"
                autoComplete="off"
                name="number"
                value={number}
                onChange={handleChange}
                required
              /><br />


              <label htmlFor="message">Registration Date</label><br />
              <DatePicker 
              selected={selectedDate}
              onChange={date =>setSelectedDate(date)}
              />
              <label htmlFor="message">Bio</label><br />
              <input
                type="text"
                style={{ height: "30px", width: "300px", marginBottom: "20px" ,height:"100px"}}
                id="bio"
                name="bio"
                value={bio}
                onChange={handleChange}
                required
              /><br />

             
             
              <input onClick={()=>{}}
               type="submit"  style={{ fontsize: "20px", color: "white", height: "40px", width: "100%", marginBottom: "20px", backgroundColor: "#3498DB", border: "none" }} value="Submit" />
            </form>
          </div>

        </div>
      </div>
      <div style={{ width: "100%" }}>
        <Footer />
      </div>
    </Adduser1>
  );
}

export default Adduser;

const Adduser1 = styled.div`
  width: 100%;
  height: 800px;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  justify-content:space-between;
  flex-wrap: wrap;
 
 

  .container1{
    ${'' /* marginTop: 700px; */}
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
     ${'' /* border: 2px solid red;  */}
    padding: 20px;
}


.join{
    /* border-bottom: 2px solid black; */
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: #3498DB;
    height: 60px;
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
    color: white;
    width: 100%;
     ${'' /* border: 2px solid orange;  */}
}
.form{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 90%;
    padding: 20px;
    color: #4A4A4A;
    font-weight: bold;
    /* border: 2px solid blue; */
    font-family: 'IBM Plex Sans Thai Looped', sans-serif;
}

.box{
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 35%;
     ${'' /* border: 2px solid red;  */}
    box-shadow: rgba(0, 0, 0, 0.35) 0px 5px 15px;
    /* padding: 10px; */
}
${'' /*   
  @media screen and (max-width: 360px) {
    .box {
      border: 2px solid black;
      margin-right:190px;
      width:30vw;
    }
   
  } */}

 @media screen and (min-width:360px) {
    .box{
        width:95%;
        backgroundColor:blue;
    }
    input[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid grey;
  border-radius: 4px;
  box-sizing: border-box;
  height:40px;
}
textarea[type=text], select {
  width: 100%;
  padding: 12px 20px;
  margin: 8px 0;
  display: inline-block;
  border: 2px solid grey;
  border-radius: 4px;
  box-sizing: border-box;
  height:40px;
}

.body{
  backgroundColor:blue;
}
}
@media screen and (min-width:768px) {
    .box{
        width:50%;
        backgroundColor:blue;
    }
    .container1{
      marginLeft:"50px";
    }
}
@media screen and (min-width:900px) {
    .box{
        width:50%;
        backgroundColor:blue;
    }
    .container1{
      marginLeft:"50px";
    }
}
@media screen and (min-width:1050px) {
    .box{
        width:30%;
        backgroundColor:blue;
    }
    .container1{
      marginLeft:"50px";
    }
}

  @media screen and (max-width: 1010px) {
      height:750px;
      .getheaddiv{
          display:block;
          margin-top:0;
          height:5px;
          z-index:100;
      }
      .gethead{
          margin-top:0;
          font-size:45px;
          padding-top:15px;
          padding-bottom:15px;
          z-index:100;
      }
      .contactform{
          width:95%;
          margin-right:0px;
          // height:640px;
          // margin-top:0;
          margin-top:150px;
      }
      .container1{
      marginLeft:"50px";
    }
  }
`;
