import firebase from 'firebase/compat/app';
import 'firebase/compat/auth';
import 'firebase/compat/firestore';


const firebaseConfig = {
    apiKey: "AIzaSyCDW4TgV5vGUp36kHi7xtPJm9m_Qmtnisg",
    authDomain: "tvish-ecommerce.firebaseapp.com",
    databaseURL: "https://tvish-ecommerce.firebaseio.com",
    projectId: "tvish-ecommerce",
    storageBucket: "tvish-ecommerce.appspot.com",
    messagingSenderId: "415755214658",
    appId: "1:415755214658:web:3e858aab2b421cd3adb2db",
    measurementId: "G-CMHSCDBWHJ",
  };

  const fire=firebase.initializeApp(firebaseConfig);

  export default fire;