import React from 'react'
import { Link } from "react-router-dom";

import List from '@mui/material/List';
import ListItem from '@mui/material/ListItem';
import ListItemText from '@mui/material/ListItemText';
import Divider from '@mui/material/Divider';
import Drawer from '@mui/material/Drawer';
import Toolbar from '@mui/material/Toolbar';

export const drawerWidth = 250;

function CustomDrawer() {
  const navItems = [
    ["Home", "/"],
    ["Add Single Product", "/addSingleProduct"], 
    ["Add Multiple Products", "/addMultipleProducts"],
    ["Add another User", "/adduser"],
  ]

  return (
    <Drawer
      sx={{
        width: drawerWidth,
        flexShrink: 0,
        '& .MuiDrawer-paper': {
          width: drawerWidth,
          boxSizing: 'border-box',
        },
      }}
      variant="permanent"
      anchor="left"
    >
      <Toolbar />
      <Divider />
      <List>
        {navItems.map((item, index) => (
          <Link to={item[1]} style={{textDecoration: "None", color: "inherit"}}>
            <ListItem button key={index}>
              <ListItemText primary={item[0]} />
            </ListItem>
          </Link>
        ))}
      </List>
    </Drawer>
  )
}

export default CustomDrawer