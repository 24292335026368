import { initializeApp } from "firebase/app";
import {
  getFirestore
} from "firebase/firestore";

import {
  getStorage
} from "firebase/storage"

const firebaseConfig = {
  apiKey: "AIzaSyCDW4TgV5vGUp36kHi7xtPJm9m_Qmtnisg",
  authDomain: "tvish-ecommerce.firebaseapp.com",
  databaseURL: "https://tvish-ecommerce.firebaseio.com",
  projectId: "tvish-ecommerce",
  storageBucket: "tvish-ecommerce.appspot.com",
  messagingSenderId: "415755214658",
  appId: "1:415755214658:web:3e858aab2b421cd3adb2db",
  measurementId: "G-CMHSCDBWHJ",
};

const app = initializeApp(firebaseConfig);
const db = getFirestore(app);
const storage = getStorage(app);

export {
  app,
  db,
  storage
};
