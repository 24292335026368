import React, { useEffect, useState } from 'react'
import { collection, getDocs } from 'firebase/firestore'
import { db } from '../firebase-config'
import { Accordion, AccordionDetails, AccordionSummary, Button, Grid, Typography } from '@mui/material'
import ExpandMoreIcon from '@mui/icons-material/ExpandMore'
import { storage } from '../firebase-config'
import { ref, getDownloadURL } from 'firebase/storage'
import { doc, setDoc, deleteDoc } from 'firebase/firestore'

function Dashboard() {
  const [docs, setDocs] = useState([])
  useEffect(()=>{
    getDocs(collection(db, 'PendingApproval/Products/All'))
    .then(snapshot => {
      const data = snapshot.docs.map(doc => ({...doc.data(), id: doc.id}))
      setDocs(data)
    })
  }, [])
  const [expanded, setExpanded] = useState(false)
  const [clothUrl, setClothUrl] = useState("")
  const [edgeUrl, setEdgeUrl]   = useState("")
  const handleChange = (id) => (e, isExpanded) => {
    const clothRef = ref(storage, `new_product_images/clothes/${id}.jpg`)
    const edgeRef  = ref(storage, `new_product_images/edges/${id}.jpg`)
    getDownloadURL(clothRef)
    .then(url => setClothUrl(url))
    getDownloadURL(edgeRef)
    .then(url => setEdgeUrl(url))
    setExpanded(isExpanded?id:false)
  }
  const approveProduct = (idx) => {
    const docRef = doc(db, 'Products', docs[idx].id)
    setDoc(docRef, docs[idx])
    deleteDoc(doc(db, 'PendingApproval/Products/All', docs[idx].id))
  }
  return (
    <Grid container>
      <Grid item xs={12} md={6}>
        <Typography variant="h4" align="center" gutterBottom>
          Pending Approval
        </Typography>
        <Grid item xs={12}>
          {docs.map((doc, idx)=> (
          <Accordion key={idx} expanded={expanded === doc.id} onChange={handleChange(doc.id)} TransitionProps={{ unmountOnExit: true }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} >
              <Grid container>
                <Grid item xs={4} textAlign="center" sx={{overflow: "hidden", textOverflow: "ellipsis"}}>{doc.id}</Grid>
                <Grid item xs={4} textAlign="center" sx={{overflow: "hidden", textOverflow: "ellipsis"}}>{doc.name}</Grid>
                <Grid item xs={4} textAlign="center" sx={{overflow: "hidden", textOverflow: "ellipsis"}}>{doc.brand}</Grid>
              </Grid>
            </AccordionSummary>
            <AccordionDetails>
              <Grid container justifyContent="space-between">
                <Grid item xs={6}>
                  <div style={{textAlign: "center"}}>
                    <img src={edgeUrl} alt="product-edge" style={{maxWidth: "100%"}} />
                  </div>
                </Grid>
                <Grid item xs={6}>
                  <div style={{textAlign: "center"}}>
                    <img src={clothUrl} alt="bg-removed-cloth" style={{maxWidth: "100%"}} />
                  </div>
                </Grid>  
              </Grid>
              {
                Object.keys(doc).map((key, idx) => (key==="tryonData"?<></>:
                  <Typography margin="dense" key={idx}>{key}: {doc[key]}</Typography>
                  )
                )
              }
              <Button fullWidth variant="outlined" onClick={() => approveProduct(idx)}>Approve</Button>
            </AccordionDetails>
          </Accordion>
          )
        )}
        </Grid>
      </Grid>
    </Grid>
  )
}

export default Dashboard